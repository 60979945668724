// VARS
$color1: #2c3e50;
$color2: #27ae60;

// MIXINS
@mixin transition($ms:300ms) {
  transition: ease-in-out $ms;
}


html {
  font-size: 16px;
}

#id-topboxes {
  background-color: #ebebeb;
}

.topboxes {
  margin-bottom: 2rem;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 1rem;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    min-width: 300px;
    max-width: 100%;
    padding: 1rem;
    font-size: 1.3rem;
    font-weight: bold;
    background-color: $color1;
    color: #ffffff;
    border: 5px solid #ffffff;
    @include transition();

    &:hover {
      box-shadow: 0 1rem 2rem rgba(#000, .5);
    }
  }

  .box__icon {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  .box__herotext {
    font-size: 2rem;
  }
}